<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.status" class="vps-detail__inner">
        <!--        <base-loader v-if="loading" />-->
        <div class="vps-detail__content">
          <!--            :img="{ src: tariff.specs.os.img, alt: tariff.specs.os.name }"-->
          <layout-title
            v-if="specsObj"
            :id="tariff.id"
            :img="{ src: tariff.osName.svg, alt: tariff.osName.name }"
            :title="tariff.title"
            :cost="periodCost"
            :specs="specsObj"
            :address="tariff.ip"
            :status="tariff.status"
            :state="tariff.serverState"
            class="vps-detail__head"
          >
            <v-popover
              v-if="isMobile && (serverId || tariff.status.code !== 2)"
              slot="btn"
              placement="bottom"
              popover-class="vps-detail__popover"
            >
              <sidebar-button />
              <vps-context-menu
                slot="popover"
                :tariff="tariff"
                :is-loading="isCurrentLoading"
                class="vps-detail__context-menu"
              />
            </v-popover>
          </layout-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>

        <main-card v-if="!isMobile" class="vps-detail__aside">
          <vps-context-menu
            v-if="serverId || tariff.status.code !== 2"
            :tariff="tariff"
            :is-loading="isCurrentLoading || isLoading"
          />
          <base-loader v-else />
        </main-card>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutTitle from '@/components/LayoutTitle/LayoutTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import VpsContextMenu from '../../components/VpsContextMenu.vue';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index.js';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import updateStatus from '@/layouts/VPS/mixins/updateStatus';
export default {
  name: 'TariffInfo',
  components: {
    LayoutTitle,
    tabs,
    SidebarButton,
    MainCard,
    VpsContextMenu,
    BaseAlert,
  },
  mixins: [storeMixin, providerChange, updateStatus],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timerId: '',
      timerCnt: 0,
      isCurrentLoading: true,
      isServerError: false,
      navRaw: [
        {
          title: this.$t('nav.view'),
          to: { name: 'vpsView' },
        },
        {
          title: this.$t('nav.config'),
          to: { name: 'vpsConfig' },
        },
        {
          title: this.$t('nav.backup'),
          to: { name: 'vpsBackup' },
          access: { name: 'status', value: true },
        },
        {
          title: this.$t('nav.history'),
          to: { name: 'vpsHistory' },
        },
        {
          title: this.$t('nav.access'),
          to: { name: 'vpsAccess' },
          access: { name: 'root', value: true },
        },
        {
          title: this.$t('nav.ip'),
          to: { name: 'vpsIp' },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleVPS.list.find(vps => vps.id === this.id);
    },
    periodCost() {
      return this.$n(this.tariff.period_cost, 'currency', this.$i18n.locale);
    },
    specsObj() {
      if (!this.tariff || !this.tariff.specs) return null;
      const specs = this.tariff.specs;
      let list = [];
      if (specs.cpu) list.push(specs.cpu.toString());
      if (specs.mem) list.push(specs.mem.toString());
      if (specs.disc) list.push(specs.disc.toString());
      const name =
        this.tariff && this.tariff.osName && this.tariff.osName.name ? this.tariff.osName.name : '';
      return {
        // head: specs.os.name,
        head: name,
        list,
      };
    },
    serverId() {
      return this.tariff ? this.tariff.serverId : null;
    },
    serverInfo() {
      return this.tariff && this.tariff.serverInfo ? this.tariff.serverInfo : null;
    },
    root() {
      return this.serverInfo ? this.serverInfo.root : false;
    },
    vmKey() {
      return localStorage.getItem('vmrogue');
    },
    nav() {
      return this.navRaw.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
    status() {
      return this.tariff.status.code !== 1 && this.provider !== '2';
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    serverId: function (val) {
      if (val) {
        if (val && this.provider !== '2') {
          this.$store.dispatch('moduleVPS/setServerInfo', { serverId: val });
          this.isCurrentLoading = false;
        }
      }
    },
    serverInfo(event) {
      // console.log(event);
      // if (!event) {}
    },
    'tariff.status': {
      handler: async function (event) {
        if (event && (event.code === '5' || event.code === '1')) {
          await this.updateVpsStatus(this.tariff.id);
        }
      },
      immediate: true,
    },
    screenWidth: function () {
      this.setIsMobile();
    },
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
  },
  created() {
    if (!this.list.length) {
      this.fetchList()
        .then(() => {
          if (this.checkNeedTariff()) this.fetchCurrent(this.id);
          else this.isCurrentLoading = false;
        })
        .then(() => this.fetchId())
        .catch(e => {
          this.isCurrentLoading = false;
          if (e.status && e.status === 520) this.isServerError = true;
        });
    } else if (this.checkNeedTariff()) {
      console.log('else');
      this.fetchCurrent(this.id);
    } else {
      this.isCurrentLoading = false;
    }
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    if (this.provider.id !== '2') {
      if (!this.serverId && !this.isLoading && (this.status === 2 || this.status === 3)) {
        this.$store.dispatch('moduleVPS/fetchDetail', { id: this.id }).then(() => {
          if (this.serverId) this.setServerInfo();
        });
      } else if (this.serverId && !this.isLoading && (this.status === 2 || this.status === 3)) {
        this.setServerInfo();
      }
    }
  },
  methods: {
    checkNeedTariff() {
      return !this.id || this.tariff.id !== this.id || !this.tariff.serverId;
    },
    fetchCurrent(id) {
      this.isCurrentLoading = true;
      this.$store
        .dispatch('moduleVPS/setCurrent', id)
        .then(tariff => {
          this.$store.dispatch('moduleVPS/fetchDetail', { id: tariff.id }).then(() => {
            // this.$store.dispatch('moduleVPS/fetchDetail', { id: id }).then(() => {
            // console.log('fetchCurrent');
            // console.log('fetch key', this.vmKey);
            // if (this.serverId && !this.vmKey && this.tariff._serverInfo) this.getVmKey(tariff.id);

            if (this.serverId) this.getVmKey(tariff.id);
            // if (this.serverId && !this.vmKey) this.getVmKey(tariff.id);
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isCurrentLoading = false;
        });
    },
    getVmKey(id) {
      this.$store.dispatch('moduleVPS/getVmKey', id);
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    fetchId() {
      this.$store.dispatch('moduleVPS/fetchId', this.id);
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleVPS/reset', 16);
      // this.$router.push({ name: 'vpsMain' });
    },
    // async updateVpsStatus() {
    //   this.timerId = setTimeout(() => {
    //     this.$store
    //       .dispatch('moduleVPS/fetchDetail', { id: this.tariff.id, detail: true })
    //       .then(async data => {
    //         if (['1', '4', '5'].includes(data.model.status)) {
    //           if (this.timerCnt < 5) this.timerCnt += 1;
    //           await this.updateVpsStatus();
    //         } else {
    //           this.timerCnt = 0;
    //           this.$store
    //             .dispatch('moduleVPS/setServerInfo', { serverId: this.serverId })
    //             .then(() => {
    //               clearTimeout(this.timerId);
    //             });
    //         }
    //       });
    //   }, 1000 * 30 * this.timerCnt);
    // },
  },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "view": "Обзор",
        "config": "Изменить конфигурацию",
        "backup": "Бэкапы",
        "history": "История",
        "access": "Доступ",
        "ip": "IP-адреса"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
